import axios from 'axios';

const API_URL = process.env.VUE_APP_SOCKET_URL;

const getAuthToken = () => {
  return localStorage.getItem('token'); // Or however you're storing the token
};

export default {
  async getGames(page = 1, perPage = 20, expand = '') {
    const response = await axios.get(`${API_URL}/api/v1/slotegrator/games`, {
      params: { page, per_page: perPage, expand },
      headers: { Authorization: `Bearer ${getAuthToken()}` }
    });
    return response.data;
  },

  async getGameLobby(gameUuid, currency) {
    const response = await axios.get(`${API_URL}/api/v1/slotegrator/game-lobby/${gameUuid}`, {
      params: { currency },
      headers: { Authorization: `Bearer ${getAuthToken()}` }
    });
    return response.data;
  },

  async initGame(gameUuid, currency) {
    try {
      const response = await axios.post(`${API_URL}/api/v1/slotegrator/init-game`, 
        { game_uuid: gameUuid, currency },
        { headers: { Authorization: `Bearer ${getAuthToken()}` } }
      );
      return response.data;
    } catch (error) {
      console.error('Error initializing game:', error);
      throw error;
    }
  },

  async initDemoGame(gameUuid, returnUrl, language = 'en') {
    const response = await axios.post(`${API_URL}/api/v1/slotegrator/init-demo-game`, 
      { game_uuid: gameUuid, return_url: returnUrl, language },
      { headers: { Authorization: `Bearer ${getAuthToken()}` } }
    );
    return response.data;
  },

  async getMerchantLimits() {
    const response = await axios.get(`${API_URL}/api/v1/slotegrator/limits`, {
      headers: { Authorization: `Bearer ${getAuthToken()}` }
    });
    return response.data;
  },

  async getJackpots() {
    const response = await axios.get(`${API_URL}/api/v1/slotegrator/jackpots`, {
      headers: { Authorization: `Bearer ${getAuthToken()}` }
    });
    return response.data;
  }
};
