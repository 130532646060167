<template>
  <div class="home">
    <div class="home-banner">

      <HomeBannerUser/>
      <HomeBannerNews/>

    </div>
    <div class="home-featured">
      <div class="featured-title">GAMES</div>
      <div class="featured-content">

        <HomeFeaturedLink game="battles"/>
        <HomeFeaturedLink game="crash"/>
        <HomeFeaturedLink game="roll"/>
        <HomeFeaturedLink game="duels"/>
        <HomeGameElement game="cases"/>
        <HomeGameElement game="upgrader"/>
        <HomeGameElement game="blackjack"/>
        <HomeGameElement game="mines"/>
        <HomeGameElement game="towers"/>
        <HomePlaceholderElement/>
        <HomePlaceholderElement/>
        <HomePlaceholderElement/>

      </div>
    </div>
    <div class="home-games">
      <div class="games-content">

        <HomeDepositMethods/>
        <Bets/>

      </div>
    </div>
  </div>
</template>

<script>
import Bets from '@/components/bets/Bets';
import HomeBannerUser from '@/components/home/HomeBannerUser';
import HomeBannerNews from '@/components/home/HomeBannerNews'
import HomeFeaturedLink from '@/components/home/HomeFeaturedLink';
import HomeGameElement from '@/components/home/HomeGameElement';
import HomePlaceholderElement from '@/components/home/HomePlaceholderElement';
import HomeDepositMethods from '@/components/home/HomeDepositMethods';

export default {
  name: 'Home',
  metaInfo: {
    title: 'Candy Casino'
  },
  components: {
    Bets,
    HomeBannerUser,
    HomeBannerNews,
    HomeFeaturedLink,
    HomeGameElement,
    HomePlaceholderElement,
    HomeDepositMethods
  }
}
</script>

<style scoped>
.home {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 45px 10px;
}

.home .home-banner {
  width: 100%;
  max-width: 1250px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.home .home-featured {
  width: 100%;
  max-width: 1250px;
  position: relative;
  padding: 70px 10px;
}

.home .home-featured {
  margin-top: 55px;
}

.home .home-featured::before,
.home .home-games::before {
  content: '';
  width: 100%;
  height: 1px;
  position: absolute;
  top: 0;
  left: 0;
  background: linear-gradient(90deg, rgba(2, 25, 44, 0) 0%, #eda10f 50%, rgba(2, 25, 44, 0) 100%);
  z-index: -1;
}

.home .home-games::before {
  background: linear-gradient(90deg, rgba(2, 25, 44, 0) 0%, #FF71EA 50%, rgba(2, 25, 44, 0) 100%);
}

.home .featured-title,
.home .games-title {
  width: 205px;
  height: 43px;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 24px;
  font-weight: 900;
  color: #ffffff;
}

.home .featured-title::before,
.home .games-title::before {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: linear-gradient(255deg, #ffb74a -25%, #eda10f 100%);
  clip-path: polygon(8px 0, calc(100% - 8px) 0, 100% 25%, 100% 75%, calc(100% - 8px) 100%, 8px 100%, 0 75%, 0 25%);
  z-index: -1;
}

.home .games-title::before {
  background: linear-gradient(255deg, #FF71EA -25%, #c347b1 100%);
}

.home .featured-content,
.home .games-content {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 12px
}

@media only screen and (max-width: 1600px) {
  .home .home-banner,
  .home .home-featured,
  .home .home-games {
    width: 100%;
  }
}

@media only screen and (max-width: 950px) {
  .home {
    padding: 25px 10px;
  }

  .home .games-content {
    margin-top: 375px;
  }
}
</style>