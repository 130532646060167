<template>
  <div class="games-container">
    <h1>Games</h1>
    <div v-if="isLoading" class="loading">Loading games...</div>
    <div v-else-if="hasError" class="error">{{ errorMessage }}</div>
    <div v-else>
      <div v-if="!currentGame" class="games-grid">
        <div v-for="game in allGames" :key="game.uuid" class="game-card">
          <img 
            :src="game.image" 
            :alt="game.name" 
            class="game-image" 
            @click="playGame(game.uuid)"
          >
          <h2>{{ game.name }}</h2>
          <p>Provider: {{ game.provider }}</p>
          <button @click="playGame(game.uuid)" class="play-button">Play</button>
          <button v-if="game.has_demo" @click="playDemoGame(game.uuid)" class="demo-button">Play Demo</button>
        </div>
      </div>
      <div v-else class="game-display">
        <button @click="closeGame" class="close-button">Close Game</button>
        <iframe :src="currentGame.url" width="100%" height="600" frameborder="0"></iframe>
      </div>
      <div v-if="!currentGame" class="pagination">
        <button @click="changePage(-1)" :disabled="currentPage === 1">Previous</button>
        <span>Page {{ currentPage }} of {{ totalPages }}</span>
        <button @click="changePage(1)" :disabled="currentPage === totalPages">Next</button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';

export default {
  name: 'Games',
  data() {
    return {
      currentGame: null,
      sessionId: null
    };
  },
  computed: {
    ...mapState('slotegrator', ['currentPage', 'totalPages']),
    ...mapGetters('slotegrator', ['allGames', 'isLoading', 'hasError', 'errorMessage']),
    isAuthenticated() {
      return !!this.$store.state.auth.authUser.user;
    },
    userBalance() {
      return this.$store.state.auth.authUser.user.balance; // Adjust this path as needed
    }
  },
  methods: {
    ...mapActions('slotegrator', ['fetchGames', 'initGame', 'initDemoGame']),
    async playGame(gameUuid) {
      if (!this.isAuthenticated) {
        console.error('User is not authenticated');
        this.$store.dispatch('notificationShow', 'Please log in to play the game.');
        return;
      }
      try {
        console.log('Initializing game with balance:', this.userBalance);
        const initData = await this.initGame({ 
          gameUuid, 
          currency: 'USD',
          balance: this.userBalance
        });
        console.log('Game initialized with data:', initData);
        this.currentGame = { url: initData.url };
        this.sessionId = initData.session_id;
      } catch (error) {
        console.error('Error playing game:', error);
        this.$store.dispatch('notificationShow', error.message || 'Failed to start the game. Please try again.');
      }
    },
    async updateBalance(newBalance) {
      console.log('Updating balance in store:', newBalance);
      try {
        await this.$store.dispatch('slotegrator/updateBalance', { 
          balance: newBalance, 
          sessionId: this.sessionId 
        });
        // Update the user's balance in your auth store
        await this.$store.dispatch('auth/updateUserBalance', newBalance);
        console.log('Balance updated successfully');
      } catch (error) {
        console.error('Error updating balance:', error);
      }
    },
    async playDemoGame(gameUuid) {
      try {
        const initData = await this.initDemoGame({ 
          gameUuid, 
          returnUrl: `${window.location.origin}/games`,
          language: 'en' // Or get this from user preferences
        });
        this.currentGame = { url: initData.url };
      } catch (error) {
        console.error('Error playing demo game:', error);
        this.$store.dispatch('notificationShow', error.message || 'Failed to start the demo game. Please try again.');
      }
    },
    closeGame() {
      this.currentGame = null;
      this.sessionId = null;
    },
    async changePage(direction) {
      const newPage = this.currentPage + direction;
      await this.fetchGames({ page: newPage });
    },
    handleGameMessage(event) {
      console.log(event)

      // Verify the origin of the message
      if (event.origin !== 'https://staging.slotegrator.com') {
        console.log('Received message from unexpected origin:', event.origin);
        return;
      }

      console.log('Received game message:', event.data);
      const { type, balance } = event.data;
      if (type === 'balance_update') {
        console.log('Updating balance to:', balance);
        this.updateBalance(balance);
      }
    }
  },
  created() {
    this.fetchGames();
  },
  mounted() {
    window.addEventListener('message', this.handleGameMessage);
  },
  beforeDestroy() {
    window.removeEventListener('message', this.handleGameMessage);
  }
};
</script>

<style scoped>
.games-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.games-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 20px;
}

.game-card {
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 10px;
  text-align: center;
}

.game-image {
  width: 100%;
  height: 150px;
  object-fit: cover;
  border-radius: 4px;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.game-image:hover {
  transform: scale(1.05);
}

.play-button, .demo-button {
  margin-top: 10px;
  padding: 5px 10px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.demo-button {
  background-color: #2196F3;
}

.pagination {
  margin-top: 20px;
  text-align: center;
}

.pagination button {
  margin: 0 10px;
  padding: 5px 10px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.pagination button:disabled {
  background-color: #ddd;
  cursor: not-allowed;
}

.loading, .error {
  text-align: center;
  margin-top: 20px;
  font-size: 18px;
}

.error {
  color: red;
}

.game-display {
  width: 100%;
  margin-top: 20px;
}

.close-button {
  margin-bottom: 10px;
  padding: 5px 10px;
  background-color: #f44336;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}
</style>