<template>
  <div class="cashier-deposit" v-if="!invoiceUrl">
    <!--<div class="deposit-section">
        <div class="section-title">Growtopia & Steam</div>

        <CashierElement v-on:click.native="modalRobuxButton()" type="roblox" method="robux" v-bind:enabled="generalSettings.robux.deposit.enabled" />
        <CashierElement v-on:click.native="modalLimitedsButton()" type="roblox" method="limiteds" v-bind:enabled="generalSettings.limited.deposit.enabled" />
        <CashierElement v-on:click.native="modalSteamButton('csgo')" type="skinify" method="csgo" v-bind:enabled="generalSettings.steam.deposit.enabled" />
        <CashierElement v-on:click.native="modalSteamButton('dota2')" type="skinify" method="dota" v-bind:enabled="generalSettings.steam.deposit.enabled" />

    </div>
    <div class="deposit-section">
        <div class="section-title">Crypto & Fiat</div>

        <CashierElement v-on:click.native="modalCreditButton()" type="fiat" method="credit" v-bind:enabled="generalSettings.credit.deposit.enabled" />
        <CashierElement v-on:click.native="modalCryptoButton('btc')" type="crypto" method="btc" v-bind:enabled="generalSettings.crypto.deposit.enabled" />
        <CashierElement v-on:click.native="modalCryptoButton('eth')" type="crypto" method="eth" v-bind:enabled="generalSettings.crypto.deposit.enabled" />
        <CashierElement v-on:click.native="modalCryptoButton('ltc')" type="crypto" method="ltc" v-bind:enabled="generalSettings.crypto.deposit.enabled" />
        <CashierElement v-on:click.native="modalCryptoButton('usdt')" type="crypto" method="usdt" v-bind:enabled="generalSettings.crypto.deposit.enabled" />
        <CashierElement v-on:click.native="modalCryptoButton('usdc')" type="crypto" method="usdc" v-bind:enabled="generalSettings.crypto.deposit.enabled" />

    </div>
    <div class="deposit-section">
        <div class="section-title">Gift Cards</div>

        <CashierElement v-on:click.native="modalGiftButton('kinguin')" type="gift" method="kinguin" v-bind:enabled="generalSettings.gift.deposit.enabled" />
        <CashierElement v-on:click.native="modalGiftButton('g2a')" type="gift" method="g2a" v-bind:enabled="generalSettings.gift.deposit.enabled" />

        <div class="section-gift">
            <div class="gift-title">Redeem Gift Card</div>
            <div class="gift-input">
                <input v-model="modalGiftCode" type="text" placeholder="XXXX-XXXX-XXXX-XXXX" />
                <button v-on:click="modalRedeemButton()" class="button-redeem" v-bind:disabled="socketSendLoading !== null">
                    <div class="button-inner">REDEEM</div>
                </button>
            </div>
        </div>

    </div>-->

    <div class="currency-section">
      <input type="text" v-model="searchQuery" placeholder="Search currency..." class="search-input"/>

      <div class="deposit-section" v-if="hasFeaturedCurrencies">
        <div class="section-title">Featured</div>
        <CashierElement v-for="currency in filteredCurrencies.Featured" :key="currency.code"
                        v-on:click.native="modalSetCurrency(currency.code)"
                        :method="currency.code"
                        :selectedCurrency="selectedCurrency"
                        :class="{ darkened: selectedCurrency && selectedCurrency !== currency.code }">
          {{ currency.fullName }}
        </CashierElement>
      </div>

      <div class="deposit-section" v-if="hasOtherCurrencies">
        <div class="section-title">Others</div>
        <CashierElement v-for="currency in filteredCurrencies.Others" :key="currency.code"
                        v-on:click.native="modalSetCurrency(currency.code)"
                        :method="currency.code"
                        :selectedCurrency="selectedCurrency"
                        :class="{ darkened: selectedCurrency && selectedCurrency !== currency.code }">
          {{ currency.fullName }}
        </CashierElement>
      </div>
    </div>

    <div class="input-section">
      <div class="input-amount">
        <span>Coins</span>
        <input type="number" v-model="inputAmount" placeholder="Enter an amount..."/>
      </div>
      <button class="button-inner" :disabled="isInputEmpty" @click="handleCheckout">
        {{ invoicePending ? 'Checkout Pending...' : selectedCurrency.toUpperCase() + ' Checkout' }}
      </button>
    </div>
  </div>
  <iframe v-else :src="invoiceUrl" width="100%" height="600px"></iframe>
</template>

<script>
import axios from 'axios';
import {mapGetters, mapActions} from 'vuex';
import CashierElement from '@/components/cashier/CashierElement';

export default {
  name: 'CashierDeposit',
  components: {
    CashierElement
  },
  data() {
    return {
      searchQuery: '',
      modalGiftCode: null,
      selectedCurrency: "btc",
      inputAmount: 100,
      invoicePending: false,
      invoiceUrl: null,
      currencies: [
        {code: "btc", fullName: "Bitcoin", category: "Featured"},
        {code: "sol", fullName: "Solana", category: "Featured"},
        {code: "trx", fullName: "Tron", category: "Featured"},
        {code: "usdterc20", fullName: "USDT", category: "Featured"},
        {code: "shib", fullName: "Shiba Inu", category: "Featured"},
        {code: "eth", fullName: "Ethereum", category: "Others"},
        {code: "xrp", fullName: "XRP", category: "Others"},
        {code: "doge", fullName: "Dogecoin", category: "Others"},
        {code: "ltc", fullName: "Litecoin", category: "Others"},
        {code: "bch", fullName: "Bitcoin Cash", category: "Others"},
        {code: "xmr", fullName: "Monero", category: "Others"},
        {code: "usdc", fullName: "USDC", category: "Others"},
        {code: "dash", fullName: "DASH", category: "Others"},
        {code: "vet", fullName: "Vechain", category: "Others"},
        {code: "uni", fullName: "Uniswap", category: "Others"},
        {code: "ada", fullName: "Cardano", category: "Others"},
        {code: "dgb", fullName: "DigiByte", category: "Others"},
      ]
    };
  },
  methods: {
    ...mapActions([
      'notificationShow',
      'modalsSetShow',
      'modalsSetData'
    ]),
    modalSetCurrency(currency) {
      this.selectedCurrency = currency;
    },
    async handleCheckout() {
      try {
        this.invoicePending = true;

        const response = await axios.post(process.env.VUE_APP_BACKEND_URL + '/nowpayments/create-invoice', {
          price: this.inputAmount,
          currency: this.selectedCurrency
        });
        if (response.data.invoice.invoice_url) this.invoiceUrl = response.data.invoice.invoice_url;

        let statusResponse;
        do {
          statusResponse = await axios.post(process.env.VUE_APP_BACKEND_URL + '/nowpayments/invoice-status', {
            invoiceId: response.data.invoice.id
          });
          if (statusResponse.data.success === false) await new Promise(resolve => setTimeout(resolve, 5000)); // Wait for 5 seconds before retrying
        } while (statusResponse.data.success === false);

        if (statusResponse.data.success === true) window.location.reload();
      } catch (error) {
        this.invoicePending = false;
        console.error('Error:', error);
      }
    }
    /*modalRobuxButton() {
      this.modalsSetShow(null);
      this.modalsSetData({typeCashier: 'deposit'});

      setTimeout(() => {
        this.modalsSetShow('Robux');
      }, 200);
    },
    modalLimitedsButton() {
      this.modalsSetShow(null);
      this.modalsSetData({typeCashier: 'deposit'});

      this.$router.push({name: 'LimitedsDeposit'});
    },
    modalSteamButton(game) {
      this.modalsSetShow(null);
      this.modalsSetData({typeCashier: 'deposit', provider: 'skinify', game: game});

      setTimeout(() => {
        this.modalsSetShow('Proceed');
      }, 200);
    },
    modalCreditButton() {
      this.modalsSetShow(null);
      this.modalsSetData({typeCashier: 'deposit'});

      setTimeout(() => {
        this.modalsSetShow('Credit');
      }, 200);
    },
    modalCryptoButton(currency) {
      this.modalsSetShow(null);
      this.modalsSetData({typeCashier: 'deposit', currency: currency});

      setTimeout(() => {
        this.modalsSetShow('Crypto');
      }, 200);
    },
    modalGiftButton(provider) {
      this.modalsSetShow(null);
      this.modalsSetData({typeCashier: 'deposit', provider: provider});

      setTimeout(() => {
        this.modalsSetShow('Gift');
      }, 200);
    },
    modalRedeemButton() {
      if (this.modalGiftCode === null || this.modalGiftCode.trim() === '') {
        this.notificationShow({type: 'error', message: 'Your entered gift code is invalid.'});
        return;
      }

      this.modalsSetShow(null);

      setTimeout(() => {
        this.modalsSetData({typeCaptcha: 'giftRedeem', data: {code: this.modalGiftCode.replaceAll('-', '')}});
        this.modalsSetShow('Captcha');
      }, 200);
    }*/
  },
  computed: {
    ...mapGetters([
      'generalSettings',
      'socketSendLoading'
    ]),
    isInputEmpty() {
      return !this.inputAmount || this.inputAmount <= 0 || this.invoicePending;
    },
    filteredCurrencies() {
      const filtered = this.currencies.filter(currency =>
          currency.code.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
          currency.fullName.toLowerCase().includes(this.searchQuery.toLowerCase())
      );

      return {
        Featured: filtered.filter(currency => currency.category === "Featured"),
        Others: filtered.filter(currency => currency.category === "Others")
      };
    },
    hasFeaturedCurrencies() {
      return this.filteredCurrencies.Featured.length > 0;
    },
    hasOtherCurrencies() {
      return this.filteredCurrencies.Others.length > 0;
    }
  }
};
</script>

<style scoped>
.currency-section {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
}

.currency-section .search-input {
  font-family: knight, serif;
  font-weight: bold;
  font-size: 16px;
  width: 100%;
  height: 38px;
  padding: 0 10px;
  border-radius: 8px;
  background: #26002b;
  color: #ffffff;
}

.input-section {
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 20px;
  color: #ffc9f7;
}

.input-section button {
  padding: 1rem;
}

.input-section .input-amount {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.input-section .input-amount input {
  font-family: knight;
  font-weight: bold;
  font-size: 16px;
  width: 100%;
  height: 38px;
  padding: 0 10px;
  border-radius: 8px;
  background: #26002b;
  color: #ffffff;
}

.input-section .input-amount .input-title {
  display: flex;
  gap: 8px;
  align-items: center;
}

.input-section .input-amount .input-title img {
  height: 18px;
}

.cashier-deposit {
  width: 100%;
  display: flex;
  gap: 24px;
}

.cashier-deposit .deposit-section {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  margin-top: 25px;
  gap: 12px;
}

.cashier-deposit .section-title {
  width: 100%;
  font-size: 14px;
  font-weight: 700;
  color: #ffc9f7;
}

.cashier-deposit .section-gift {
  width: calc(50% - 18px);
  margin-left: 12px;
}

.cashier-deposit .gift-title {
  font-size: 13px;
  font-weight: 400;
  color: #ab51b1;
}

.cashier-deposit .gift-input {
  width: 100%;
  height: 64px;
  position: relative;
  margin-top: 7px;
}

.cashier-deposit .gift-input input {
  width: 100%;
  height: 100%;
  padding: 0 115px 0 20px;
  border-radius: 12px;
  font-size: 13px;
  font-weight: 400;
  color: #ffffff;
  background: #2e0731;
  border: 1px dashed #511247;
}

.cashier-deposit .gift-input input::placeholder {
  color: #bbbfd0;
}

.cashier-deposit button.button-redeem {
  width: 95px;
  height: 36px;
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translate(0, -50%);
  transition: all .2s;
}

.button-inner:hover {
  transition-duration: .2s;
  opacity: .8;
}

.button-inner:active {
  transition-duration: .2s;
  scale: .95;
}

.button-inner:disabled {
  opacity: 30%;
}

.button-inner {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  font-weight: 800;
  color: #ffffff;
  background: linear-gradient(255deg, #ff00fb 0%, #aa00a7 100%);
  clip-path: polygon(5px 0, calc(100% - 5px) 0, 100% 25%, 100% 75%, calc(100% - 5px) 100%, 5px 100%, 0 75%, 0 25%);
}

@media only screen and (max-width: 840px) {
  .cashier-deposit {
    flex-direction: column-reverse;
  }

  .cashier-deposit .input-section {
    width: 100%;
  }
}
</style>
