<template>
    <div class="game-play">
      <div v-if="loading">Loading game...</div>
      <iframe v-else :src="gameUrl" width="100%" height="600" frameborder="0"></iframe>
    </div>
  </template>
  
  <script>
  import { mapActions } from 'vuex';
  
  export default {
    name: 'GamePlay',
    data() {
      return {
        loading: true,
        gameUrl: ''
      };
    },
    methods: {
      ...mapActions('slotegrator', ['initGame']),
      async loadGame() {
        try {
          const { gameUuid } = this.$route.params;
          const initData = await this.initGame({ gameUuid, currency: 'EUR' });
          this.gameUrl = initData.url;
          this.loading = false;
        } catch (error) {
          console.error('Failed to initialize game:', error);
          // Handle error (e.g., show an error message to the user)
        }
      }
    },
    created() {
      this.loadGame();
    }
  };
  </script>
  
  <style scoped>
  .game-play {
    width: 100%;
    height: 600px;
  }
  </style>