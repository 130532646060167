import slotegratorService from '@/services/slotegratorService';

const state = {
  games: [],
  loading: false,
  error: null,
  currentPage: 1,
  totalPages: 1,
  currentGameUrl: null,
  balance: 0
};

const mutations = {
  SET_GAMES(state, games) {
    state.games = games;
  },
  SET_LOADING(state, status) {
    state.loading = status;
  },
  SET_ERROR(state, error) {
    state.error = error;
  },
  SET_PAGINATION(state, { currentPage, totalPages }) {
    state.currentPage = currentPage;
    state.totalPages = totalPages;
  },
  SET_CURRENT_GAME_URL(state, url) {
    state.currentGameUrl = url;
  },
  SET_BALANCE(state, balance) {
    state.balance = balance;
  }
};

const actions = {
  async fetchGames({ commit }, { page = 1, perPage = 20 } = {}) {
    commit('SET_LOADING', true);
    try {
      const response = await slotegratorService.getGames(page, perPage);
      commit('SET_GAMES', response.items);
      commit('SET_PAGINATION', {
        currentPage: response._meta.currentPage,
        totalPages: response._meta.pageCount
      });
    } catch (error) {
      commit('SET_ERROR', error.message);
    } finally {
      commit('SET_LOADING', false);
    }
  },

  async initGame({ commit }, { gameUuid, currency, balance }) {
    commit('SET_LOADING', true);
    try {
      console.log('Vuex: Initializing game', { gameUuid, currency, balance });
      const initData = await slotegratorService.initGame(gameUuid, currency, balance);
      commit('SET_CURRENT_GAME_URL', initData.url);
      commit('SET_BALANCE', balance);
      commit('SET_LOADING', false);
      console.log('Vuex: Game initialized', initData);
      return initData;
    } catch (error) {
      console.error('Vuex: Error initializing game', error);
      commit('SET_ERROR', error.message);
      commit('SET_LOADING', false);
      throw error;
    }
  },

  async initDemoGame({ commit }, { gameUuid, returnUrl, language }) {
    commit('SET_LOADING', true);
    try {
      const initData = await slotegratorService.initDemoGame(gameUuid, returnUrl, language);
      commit('SET_LOADING', false);
      return initData;
    } catch (error) {
      commit('SET_ERROR', error.message);
      commit('SET_LOADING', false);
      throw error;
    }
  },

  async updateBalance({ commit }, { balance, sessionId }) {
    console.log('Vuex: Updating balance', { balance, sessionId });
    try {
      await slotegratorService.notifyBalanceChange(balance, sessionId);
      commit('SET_BALANCE', balance);
      console.log('Vuex: Balance updated successfully');
    } catch (error) {
      console.error('Vuex: Error updating balance', error);
      throw error;
    }
  }
};

const getters = {
  allGames: state => state.games,
  isLoading: state => state.loading,
  hasError: state => state.error !== null,
  errorMessage: state => state.error,
  currentPage: state => state.currentPage,
  totalPages: state => state.totalPages,
  currentGameUrl: state => state.currentGameUrl,
  currentBalance: state => state.balance
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};