<template>
  <div
      class="home-placeholder-element"
      v-bind:style="{ 'height': homeHeight + 'px' }"
      ref="placeholderLink"
  >
    <div class="element-inner">
      <div class="text-inner">COMING SOON</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HomePlaceholderElement',
  data() {
    return {
      homeHeight: 170
    }
  },
  methods: {
    homeSetHeight() {
      this.homeHeight = Number(this.$refs.placeholderLink.scrollWidth * 0.574324).toFixed(4);
    }
  },
  mounted() {
    window.addEventListener('resize', this.homeSetHeight);
    this.homeSetHeight();
  },
  destroyed() {
    window.removeEventListener('resize', this.homeSetHeight);
  }
}
</script>

<style scoped>
.home-placeholder-element {
  height: 150px;
  width: calc(25% - 18px);
  position: relative;
  padding: 1px;
  /* font-family: 'Rubik'; */
}

.home-placeholder-element:nth-child(1),
.home-placeholder-element:nth-child(2),
.home-placeholder-element:nth-child(3),
.home-placeholder-element:nth-child(4) {
  margin-top: 0;
}

.home-placeholder-element:nth-child(4n) {
  margin-right: 0;
}

.home-placeholder-element::before {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #934887 100%);
  clip-path: polygon(18px 0, calc(100% - 18px) 0, 100% 25%, 100% 75%, calc(100% - 18px) 100%, 18px 100%, 0 75%, 0 25%);
  z-index: -1;
}

.home-placeholder-element::after {
  content: '';
  width: calc(100% - 2px);
  height: calc(100% - 2px);
  position: absolute;
  top: 1px;
  left: 1px;
  background-image: url('~@/assets/img/games/backup/placeholder.webp');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  clip-path: polygon(18px 0, calc(100% - 18px) 0, 100% 25%, 100% 75%, calc(100% - 18px) 100%, 18px 100%, 0 75%, 0 25%);
  filter: blur(4px);
  z-index: -1;
}

.home-placeholder-element .element-inner {
  width: 100%;
  height: 100%;
  background: rgb(60, 0, 50);
  clip-path: polygon(18px 0, calc(100% - 18px) 0, 100% 25%, 100% 75%, calc(100% - 18px) 100%, 18px 100%, 0 75%, 0 25%);
}

.home-placeholder-element .text-inner {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-weight: 800;
  color: #ecb7e4;
}

@media only screen and (max-width: 1050px) {

  .home-placeholder-element {
    width: calc(25% - 11.25px);
  }

}

@media only screen and (max-width: 800px) {

  .home-placeholder-element {
    width: calc(50% - 7.5px);
  }

  .home-placeholder-element:nth-child(2n) {
    margin-right: 0;
  }
}

@media only screen and (max-width: 450px) {

  .home-placeholder-element {
    height: 95px;
  }

  .home-placeholder-element .text-inner {
    font-size: 18px;
  }


}
</style>
